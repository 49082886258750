body {
    background-color: #1f1f1f;
}

.onboarding-page {
    width: 100%;
    height: calc(100vh - 30px);
}

.onboarding-header img {
    width: 250px;
    margin: 20px 0 40px;
}

.onboarding-swiper {
    width: 100%;
}

.onboarding-swiper img {
    width: 100%;
    margin: auto;
}

.slide-image {
    border-radius: 20px;
}

.onboarding-text {
    margin-top: 25px;
    margin-bottom: 15px;
    height: 50px;
    text-align: center;
}

.onboarding-text p {
    color: white;
    font-size: 13px;
}

.swiper-progress-bar {
    width: 90%;
    height: 3px;
    position: relative;
    margin: 10px auto;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.3);
}

.swiper-progress-bar .progress {
    height: inherit;
    border-radius: 3px;
    background: #468dff;
}

.swiper-counter {
    width: 60px;
    padding: 10px;
    border-radius: 18px;
    text-align: center;
    color: #FFFFFF;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.1);
}

.onboarding-page .large-blue-button {
    width: 250px;
    margin-top: 10px;
}
