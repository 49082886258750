.modal.modal-wrapper {
    display: block;
}

.modal.modal-wrapper h3 {
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
}

.modal.modal-wrapper .selected {
    border: 3px solid #468dff;
    border-radius: 70px;
}

.modal.modal-wrapper .avatars-list {
    width: 100%;
    margin: 30px auto 0;
}

.modal.modal-wrapper .badge-details {
    width: 80%;
    margin: 20px auto;
    text-align: center;
}

.modal.modal-wrapper .badge-details h3 {
    font-size: 20px;
    font-weight: 500;
}

.modal.modal-wrapper .avatar-img {
    cursor: pointer;
}

.modal.modal-wrapper .avatar-img p {
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 2px;
}

.modal.modal-wrapper .modal-body p {
    color: var(--white);
}

.modal.modal-wrapper .modal-body .badge-container {
    display: block;
    margin: 0 auto;
    width: 310px;
    height: 270px;
    padding: 2px;
    background: #468dff;
    box-sizing: border-box;
    -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
    -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.modal.modal-wrapper .modal-body .badge-container .hex-background {
    background-color: #1E1D1D;
    width: 306px;
    height: 266px;
    -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
    -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.modal.modal-wrapper .modal-body .badge-container img {
    max-width: 150px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.modal.modal-wrapper .modal-body .badge-container span {
    font-size: 75px;
    font-weight: 100;
    color: #468dff;
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
