.missions {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.missions .react-tabs,
.missions .react-tabs__tab-panel--selected {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.missions .list {
    background: var(--heavy-metal);
    border-radius: 10px;
    max-width: 1320px;
}

.missions .mission-btn {
    font-size: 10px;
    font-weight: 300;
    border: none;
    background: none;
    color: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    padding: 0;
    text-transform: uppercase;
    margin: 10px 15px;
}

.missions .mission-item {
    padding: 15px;
    background-color: #1E1D1D;
    border-radius: 10px;
    margin: 15px;
}

.missions .mission-item img {
    border-radius: 10px;
    object-fit: cover;
    object-position: 28%;
}

.missions .mission-item .content {
    flex: 1;
    padding: 0 30px 0 20px;
}

.missions .mission-item .content h2 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 15px;
}

.missions .mission-item button {
    font-size: 10px;
    background-color: #FFFFFF;
    border: 1px solid gray;
    color: #000000;
    text-transform: uppercase;
    border-radius: 20px;
    padding: 7px 15px;
}

.missions .mission-item .mission-date,
.missions .mission-item .mission-score {
    font-size: 10px;
    line-height: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    color: #FFFFFF;
    text-transform: uppercase;
    border-radius: 5px;
}

.missions .mission-item .mission-score {
    padding: 1px 6px 3px 1px;
}

.missions .mission-item .mission-date {
    margin-left: 6px;
    padding: 3px 6px 3px 3px;
}

.missions .mission-item .mission-score span {
    margin-top: 3px;
    margin-left: 2px;
}

.missions .mission-item .mission-date span {
    margin-top: 2px;
    margin-left: 6px;
}

.missions .mission-item .mission-progress-bar {
    width: 100%;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 10px;
}

.missions .mission-item .mission-progress {
    height: 4px;
    background-color: #468dff;
    border-radius: 10px;
}

.missions .mission-item .progress-text {
    font-size: 10px;
    width: 40px;
}

.missions .blue-item,
.missions .dark-item {
    padding: 20px;
    border-radius: 10px;
}

.missions .dark-item {
    background-color: #1E1D1D;
}

.missions .blue-item {
    background-color: #468dff;
}

.missions .blue-item h4,
.missions .dark-item h4 {
    font-size: 15px;
    font-weight: 300;
}

.missions .blue-item p,
.missions .dark-item p {
    font-size: 45px;
    font-weight: 100;
    text-align: right;
}

.missions .tab-header,
.adventure-details .tab-header,
.global-score .tab-header {
    padding: 8px 15px;
    background-color: var(--heavy-metal);
    color: var(--white);
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0;
    list-style: none;
    border-radius: 50px;
    min-width: 95px;
    margin-right: 15px;
    cursor: pointer;
    outline: none;
}

.missions .react-tabs__tab--selected,
.adventure-details .react-tabs__tab--selected,
.global-score .react-tabs__tab--selected {
    background: linear-gradient(160deg, #35AAFF 0%, #194BFB 100%);
}

.missions .empty-list {
    text-align: center;
    padding: 100px;
    font-size: 13px;
    font-style: italic;
    font-weight: 300;
}
