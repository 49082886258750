:root {
    --black: rgba(0, 0, 0, 1);
    --licorice: rgba(22, 21, 21, 0.8);
    --eerie-black: rgba(30, 29, 29, 1);
    --heavy-metal: rgba(44, 44, 44, 0.8);
    --heavy-metal-t: rgba(44, 44, 44, 0.1);
    --storm-dust: rgba(96, 96, 96, 1);
    --silver-chalice: rgba(173, 173, 173, 1);
    --white: rgba(255, 255, 255, 1);
}

/*
    1. General
    2. Sidebar
    3. Content
    4. Dashboard
    5. Score
*/

/******************* 1. General *******************/
body {
    font-family: "Prompt", sans-serif;
    margin: 15px;
    background-color: #121212;
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 0.05em;
}

a {
    text-decoration: none;
}

h1, p {
    margin-bottom: 0;
}

.gradient-text {
    background: linear-gradient(178deg, #35AAFF 0%, #194BFB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.large-blue-button {
    color: #FFFFFF;
    width: 100%;
    padding: 10px 15px 8px;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 1px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    background: linear-gradient(178deg, #35AAFF 0%, #194BFB 100%);
    border-radius: 8px;
    text-decoration: none;
}

.large-dark-button {
    color: #FFFFFF;
    width: 100%;
    padding: 10px 15px 8px;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 1px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    background: var(--storm-dust);
    border-radius: 8px;
    text-decoration: none;
}

.large-blue-button.disabled {
    cursor: not-allowed;
    background: gray;
}

.loading-container {
    padding: 150px 0 !important;
    position: relative;
}

.overflow-scroll::-webkit-scrollbar {
    display: none;
}

/******************* 2. Sidebar *******************/
.sidebar {
    height: calc(100vh - 30px);
    width: 80px;
    position: fixed;
    top: 15px;
    left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sidebar .sidebar-content {
    background-color: #1E1D1D;
    border-radius: 10px;
    padding: 5px;
    margin: 40px 0;
}

.sidebar .sidebar-logo {
    padding: 10px;
    display: block;
}

.sidebar .sidebar-logo:hover {
    background-color: unset;
}

.sidebar .sidebar-logo img {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.sidebar .sidebar-menu {
    margin-top: 80px;
}

.sidebar .sidebar-menu a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    color: #979797;
    margin-bottom: 15px;
    height: 64px;
}

.sidebar .sidebar-menu a span {
    font-size: 10px;
    letter-spacing: 0.5px;
    padding-bottom: 7px;
    display: block;
}

.sidebar .sidebar-menu a.active {
    background-color: rgba(255, 255, 255, 0.15);
    color: #FFFFFF;
}

.sidebar a:hover {
    background-color: rgba(255, 255, 255, 0.15);
}

.sidebar .logout-btn {
    text-align: center;
    display: block;
    border-radius: 5px;
    padding: 15px;
}

.sidebar .logout-btn:hover svg path {
    fill: #FFFFFF;
}

/******************* 3. Content *******************/
.page-content {
    background: linear-gradient(-15deg, #1E1D1D 50%, #275884 100%);
    width: calc(100% - 95px);
    height: calc(100vh - 30px);
    margin-left: auto;
    position: relative;
    padding: 15px;
    border-radius: 10px;
}

.page-content h1 {
    font-size: 22px;
    font-weight: 300;
    margin-left: 10px;
}

.item {
    padding: 20px;
    background-color: #121212;
    border-radius: 10px;
    margin-top: 15px;
}

/******************* 4. Dashboard *******************/

.dashboard .dashboard-blocks {
    flex: 1;
    overflow: hidden;
}

.dashboard .dashboard-blocks > .col-6 .item {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.dashboard .dashboard-blocks > .col-6 .item .overflow-scroll {
    flex: 1;
}

.dashboard-header {
    padding: 10px 0;
}

.dashboard-header .header-score {
    background-color: #1E1D1D;
    border-radius: 8px;
    padding: 8px 12px;
    cursor: pointer;
}

.dashboard-header .header-score .score {
    color: #468dff;
    font-size: 16px;
    font-weight: 300;
    padding-right: 10px;
}

.dashboard-header .header-score .name {
    font-size: 13px;
    line-height: 13px;
    padding: 0 12px;
    margin-left: 12px;
    border-left: 1px solid #666666;
}

.message-item {
    padding: 0 !important;
    background-image: url("../img/message/bg-message.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.message-item .message-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 30px 25px 25px;
}

.message-item .subtitle {
    font-size: 13px;
    border-radius: 5px;
    padding: 5px 8px;
    display: inline-block;
    background-color: rgba(70, 141, 255, 0.2);
}

.message-item h2 {
    font-size: 30px;
    margin: 20px 0 50px;
    max-width: 300px;
    font-weight: 300;
}

.message-item button {
    font-size: 12px;
    background: none;
    border: none;
    color: #468dff;
    text-transform: uppercase;
}

.message-item button .play-icon {
    border-radius: 15px;
    width: 26px;
    height: 26px;
    display: inline-block;
    background-color: #468dff;
    padding: 6px 10px;
    margin-right: 10px;
}

.message-item button .play-icon span {
    box-sizing: border-box;
    border-color: transparent transparent transparent #1E1D1D;
    border-style: solid;
    border-width: 6px 0 6px 8px;
    display: inline-block;
}

.dashboard .mission-title,
.global-score .score-title {
    font-size: 16px;
    font-weight: 300;
    margin: 10px 15px;
}

.dashboard .mission-btn,
.global-score .score-btn {
    font-size: 10px;
    font-weight: 300;
    border: none;
    background: none;
    color: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    padding: 0;
    text-transform: uppercase;
    margin: 10px 15px;
}

.dashboard .mission-item {
    padding: 15px;
    background-color: #1E1D1D;
    border-radius: 10px;
    margin-top: 15px;
    position: relative;
    overflow: hidden;
}

.dashboard .mission-item img {
    border-radius: 10px;
    object-fit: cover;
    object-position: 28%;
}

.dashboard .mission-item .content {
    flex: 1;
    padding: 0 15px;
}

.dashboard .mission-item .content h4 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
}

.dashboard .mission-item button {
    font-size: 10px;
    background-color: #FFFFFF;
    border: 1px solid gray;
    color: #000000;
    text-transform: uppercase;
    border-radius: 20px;
    padding: 7px 15px;
}

.dashboard .mission-item .mission-date,
.dashboard .mission-item .mission-score {
    font-size: 10px;
    line-height: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    color: #FFFFFF;
    text-transform: uppercase;
    border-radius: 5px;
}

.dashboard .mission-item .mission-score {
    padding: 1px 6px 3px 1px;
}

.dashboard .mission-item .mission-date {
    margin-left: 6px;
    padding: 3px 6px 3px 3px;
}

.dashboard .mission-item .mission-score span {
    margin-top: 3px;
    margin-left: 2px;
}

.dashboard .mission-item .mission-date span {
    margin-top: 2px;
    margin-left: 6px;
}

.dashboard .mission-item .mission-progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 10px;
}

.dashboard .mission-item .mission-progress {
    width: 10%;
    height: 4px;
    background-color: #468dff;
    border-radius: 10px;
}

.dashboard .stats .rank {
    padding-right: 30px;
    position: relative;
}

.dashboard .stats .rank span {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    position: absolute;
    top: 0;
    right: 0;
    letter-spacing: 0;
}

.dashboard .blue-item,
.dashboard .dark-item {
    padding: 18px 20px;
    border-radius: 10px;
}

.dashboard .dark-item {
    background-color: #1E1D1D;
}

.dashboard .blue-item {
    background-color: #468dff;
}

.dashboard .blue-item h4,
.dashboard .dark-item h4 {
    font-size: 15px;
    font-weight: 300;
}

.dashboard .blue-item p,
.dashboard .dark-item p {
    font-size: 45px;
    line-height: 45px;
    font-weight: 100;
    text-align: right;
}

.dashboard .badges-carousel img {
    height: 70px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.dashboard .badges-carousel p {
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
    letter-spacing: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dashboard .badges-carousel .carousel-control-prev-icon,
.dashboard .badges-carousel .carousel-control-next-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.dashboard .badges-carousel .carousel-control-prev,
.dashboard .badges-carousel .carousel-control-next {
    margin-bottom: 28px;
}

.dashboard .dark-item .badge-number {
    font-size: 12px;
    letter-spacing: 0;
    border-radius: 5px;
    padding: 2px 5px;
    background-color: rgba(70, 141, 255, 0.2);
}

.dashboard .badge-container {
    display: block;
    margin: 0 auto;
    width: 120px;
    height: 100px;
    padding: 1px;
    background: #468dff;
    box-sizing: border-box;
    -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
    -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.dashboard .badge-container .hex-background {
    background-color: #1E1D1D;
    width: 118px;
    height: 98px;
    -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
    -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

/******************* 5. Score *******************/
.global-score,
.global-score > .row > .col-6 {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.global-score > .row {
    flex: 1;
    overflow: hidden;
}

.global-score > .row > .col-6 .item,
.global-score > .row > .col-6 .item .react-tabs,
.global-score > .row > .col-6 .item .react-tabs__tab-panel--selected {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.global-score .item {
    position: relative;
}

.global-score .item .score-btn {
    position: absolute;
    top: 18px;
    right: 15px;
}

.global-score .ranking-item:first-child {
    margin-top: 0 !important;
}

.global-score .no-data {
    background-color: var(--heavy-metal);
    padding: 20px;
    border-radius: 12px;
}

/******************* 6. OnlyDesktop *******************/
.onlyDesktop .splash {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-image: url('../img/login/rocket_splash.jpeg');
    background-size: cover;
    background-position: center;
}

.onlyDesktop .content {
    background-color: rgba(0, 0, 0, 0.5);
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 5px;
    max-width: 342px;
}

.onlyDesktop span {
    width: 100%;
    border-radius: 12px;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
}


.onlyDesktop .store-content {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.onlyDesktop .appstore {
    width: 100px;
    margin: 0 0 -30px 0;
}

.onlyDesktop .googleStore {
    width: 100px;
    margin: -34px 0 0 0;
}

/* Responsive
 *********************************/

@media (min-width: 1200px) {
    .dashboard,
    .dashboard .dashboard-blocks > .col-6 {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 1199px) {
    .dashboard,
    .profile-dashboard {
        height: 100%;
        overflow: scroll;
    }

    .page-content {
        width: calc(100% - 90px);
    }

    .sidebar {
        width: 75px;
    }

    .sidebar .sidebar-content:first-child {
        margin: 25px 0;
    }

    .sidebar .sidebar-content {
        margin: 0 0 25px;
    }

    .sidebar .sidebar-menu {
        margin-top: 25px;
    }

    .sidebar .sidebar-menu a span {
        font-size: 9px;
        padding-bottom: 5px;
    }

    .sidebar .sidebar-menu svg {
        max-width: 38px;
    }

    .sidebar .sidebar-menu a {
        margin-bottom: 5px;
        height: 56px;
    }

    .item {
        padding: 15px;
    }

    .dashboard .mission-title,
    .dashboard .mission-btn,
    .global-score .score-title,
    .global-score .score-btn {
        margin: 6px 12px;
    }

    .dashboard-header {
        padding: 0;
    }

    .page-content h1 {
        font-size: 20px;
    }

    .message-item h2 {
        font-size: 25px;
        margin: 20px 0 35px;
    }

    .message-item .message-overlay {
        padding: 20px 20px 20px;
    }

    .dashboard .blue-item, .dashboard .dark-item {
        padding: 15px;
    }

    .dashboard .mission-title, .global-score .score-title,
    .dashboard .blue-item h4, .dashboard .dark-item h4,
    .profile-dashboard h3 {
        font-size: 14px;
    }

    .profile-dashboard h2 {
        font-size: 24px;
    }

    .profile-dashboard .badges h2 {
        font-size: 18px;
    }

    .carousel-control-prev {
        left: -8px;
    }

    .carousel-control-next {
        right: -8px;
    }

    .dashboard .badges-carousel .carousel-control-prev-icon,
    .dashboard .badges-carousel .carousel-control-next-icon {
        width: 1.3rem;
        height: 1.3rem;
    }

    .dashboard .mission-item .content h4 {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .dashboard .mission-item img {
        width: 70px;
        height: 70px;
    }

    .dashboard .mission-item button {
        font-size: 9px;
        padding: 6px 12px;
    }

    .dashboard .mission-item .mission-date, .dashboard .mission-item .mission-score {
        font-size: 9px;
    }

    .adventure-modal .modal-header {
        height: 160px;
    }

    .adventure-modal .modal-body .adventure-data h5 {
        font-size: 22px;
    }

    .adventure-modal .modal-body .adventure-metadata p {
        font-size: 15px;
    }

    .missions .tab-header,
    .adventure-details .tab-header,
    .global-score .tab-header {
        font-size: 12px;
        margin-right: 12px;
    }

    .adventure-details .ranking-item .ranking-info p, .global-score .ranking-item .ranking-info p {
        font-size: 14px;
        line-height: 16px;
    }

    .adventure-details .ranking-item .ranking-info span, .global-score .ranking-item .ranking-info span {
        padding: 2px 5px;
        font-size: 8px;
    }

    .adventure-details .ranking-item .rank, .global-score .ranking-item .rank {
        margin-right: 6px;
        font-size: 13px;
    }

    .adventure-details .ranking-item .score, .global-score .ranking-item .score {
        font-size: 15px;
    }

    .adventure-details .data-section {
        padding: 15px;
        font-size: 14px;
    }

    .adventure-details .data-section h2 {
        font-size: 15px;
        margin: 8px 15px 15px 8px;
    }

    .adventure-details .large-dark-button {
        font-size: 12px;
    }
}
