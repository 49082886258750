.profile-dashboard h2 {
    font-size: 28px;
    font-weight: 400;
}

.profile-dashboard .badges h2 {
    font-size: 20px;
    font-weight: 300;
    margin-top: 8px;
    margin-left: 10px;
}

.profile-dashboard h3 {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0;
    margin-left: 8px;
}

.profile-dashboard .badges .badges-list {
    padding: 35px 0 15px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
}

.profile-dashboard .frame {
    background-color: var(--heavy-metal);
    border-radius: 5px;
    padding: 8px 25px 8px 20px;
    margin: 0 8px;
}

.profile-dashboard .hex {
    float: left;
    margin-left: 10px;
    margin-bottom: -19px;
}

.profile-dashboard .hex .top {
    width: 0;
    border-bottom: 30px solid var(--heavy-metal);
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
}

.profile-dashboard .hex .middle {
    width: 104px;
    height: 60px;
    text-align: center;
    background: var(--heavy-metal);
}

.profile-dashboard .hex .middle a {
    width: 100%;
    height: 100%;
    display: block;
}

.profile-dashboard .hex .bottom {
    width: 0;
    border-top: 30px solid var(--heavy-metal);
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
}

.profile-dashboard .hex-row {
    clear: left;
}

.profile-dashboard .hex-row.even {
    margin-left: 57px;
}

.profile-dashboard .middle img {
    width: auto;
    height: 100%;
}

.profile-dashboard .profile-edit-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    right: 8px;
    background-color: #FFFFFF;
    z-index: 1;
    border-radius: 15px;
    padding: 3px 7px;
    cursor: pointer;
}
