/* Default Modal
 *********************************/

.modal-details.show {
    display: block;
}

.modal-details {
    background-color: rgb(29 30 30 / 50%);
    backdrop-filter: blur(5px);
}

.modal-details button.close {
    position: absolute;
    top: 0;
    right: -45px;
    border: none;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 15px;
}

.modal-details button.close span {
    width: 18px;
    height: 2px;
    display: block;
    background-color: #1E1D1D;
}

.modal-details button.close span:nth-child(1) {
    transform: rotate(45deg) translateX(1px);
}

.modal-details button.close span:nth-child(2) {
    transform: rotate(-45deg) translateX(1px);
}

.modal-details .modal-content {
    background-color: transparent;
    border: none;
}

.modal-details .modal-header {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: none;
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 40px 25px;
}

.modal-details .modal-body {
    background-color: #1E1D1D;
    margin-bottom: 15px;
    border-radius: 10px;
}

.modal-details .modal-footer {
    border: none;
    padding: 0;
}

/* SpeedQuiz Modal
 *********************************/

.speed-quiz-details .modal-header {
    background-image: url("../../../../../../assets/img/speedQuiz/bg-speedQuiz.jpeg");
}

.speed-quiz-details .modal-header p {
    font-size: 14px;
    margin-top: 15px;
}

.speed-quiz-details .modal-header b {
    font-size: 28px;
    font-weight: 600;
}

.speed-quiz-details .modal-body h5,
.speed-quiz-details .modal-body .subtitle {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    display: block;
    margin-bottom: 0;
}

.speed-quiz-details .modal-body strong {
    font-weight: 700;
}

.speed-quiz-details .round .round-details p,
.speed-quiz-details .round .round-details span {
    opacity: 0.3;
}

.speed-quiz-details .round .round-details.active p,
.speed-quiz-details .round .round-details.active span {
    opacity: 1;
}

.speed-quiz-details .round .round-details p {
    font-size: 18px;
    font-weight: 600;
}

.speed-quiz-details .round .round-details span {
    font-size: 12px;
    font-weight: 300;
}

.speed-quiz-details .round .rectangle {
    background-color: #2C2C2C;
    height: 4px;
    margin: 7px 0;
    width: 32px;
    border-radius: 3px;
}

.speed-quiz-details .round .rectangle {
    background-color: #2C2C2C;
    height: 4px;
    margin: 7px 0;
    width: 32px;
    border-radius: 3px;
}

.speed-quiz-details .rectangle.green {
    background: linear-gradient(180deg, #2BE1B7 0%, #068570 100%);
}

.speed-quiz-details .rectangle.red {
    background: linear-gradient(180deg, #FF1A37 0%, #600000 100%);
}

/* Adventure Modal
 *********************************/

.adventure-modal .modal-header {
    height: 200px;
    background-image: url("../../../../../../assets/img/adventure/default-adventure.jpeg");
}

.adventure-modal .modal-body .adventure-metadata p {
    font-size: 17px;
    font-weight: 700;
}

.adventure-modal .modal-body .adventure-metadata span {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #9C9C9C;
}

.adventure-modal .modal-body .adventure-data h5 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
}

.adventure-modal .modal-body .adventure-data .description {
    max-height: calc(100vh - 400px);
}

.adventure-modal .modal-body .adventure-data p {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.5px;
}

.adventure-modal .modal-body .adventure-data img {
    margin-top: 15px;
    margin-bottom: 15px;
}

/* Message Modal
 *********************************/

.message-details .modal-header {
    height: 150px;
    background-image: url("../../../../../../assets/img/message/bg-message.png");
}

/* Responsive
 *********************************/

@media (min-width: 768px) {
    .modal-details .modal-dialog {
        max-width: 650px;
        margin-right: auto;
        margin-left: auto;
    }
}
