.team-dashboard {
    display: flex;
    align-items: flex-start;
    background: linear-gradient(180deg,
    rgba(50, 159, 255, 0.46) 0%,
    rgba(50, 159, 255, 0) 74.56%);
    border: 0 none;
    gap: 20px;
    height: 100vh;
    width: 100%;
    border-radius: 12px;
}

.team-dashboard .overlap-group {
    -webkit-backdrop-filter: blur(54px) brightness(100%);
    backdrop-filter: blur(54px) brightness(100%);
    border-radius: 10px;
    flex-direction: column;
    padding: 36px 35px;
    width: 100%
}


.team-dashboard .adventure-data {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex: 1;
    justify-content: center;
}

.team-dashboard .adventure-data > div {
    margin-top: 1%;
    background-color: var(--licorice);
    border-radius: 10px;
    width: 50%;
    padding: 20px 30px;
}


.team-dashboard .adventure-data > div:first-child {
    margin-right: 10px;
}

.team-dashboard .adventure-data > div:last-child {
    margin-left: 10px;
}

.team-dashboard .data-section {
    display: flex;
    height: calc(100vh);
    flex-direction: column;
}

.team-dashboard .profile-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.team-dashboard .edit-btn {
    padding: 5px 15px;
    background-color: var(--white);
    color: var(--licorice);
    border-radius: 5px;
    text-transform: uppercase;
}

.team-dashboard .frame {
    background-color: var(--heavy-metal);
    border-radius: 5px;
    flex: 1;
    gap: 1px;
    padding: 6px 12px;
}

.team-dashboard .hex {
    float: left;
    margin-left: 10px;
    margin-bottom: -19px;
}

.team-dashboard .hex .top {
    width: 0;
    border-bottom: 30px solid var(--heavy-metal);
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
}

.team-dashboard .hex .middle {
    width: 104px;
    height: 60px;
    background: var(--heavy-metal);
}

.team-dashboard .hex .bottom {
    width: 0;
    border-top: 30px solid var(--heavy-metal);
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
}

.team-dashboard .hex-row {
    clear: left;
}

.team-dashboard .hex-row.even {
    margin-left: 57px;
}

.team-dashboard .images-mm {
    width: 70%;
    height: auto;
    margin-left: 15%;
    transform: translateY(-15%);
}

.team-dashboard .profile-edit-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    right: 14px;
    background-color: #FFFFFF;
    z-index: 1;
    border-radius: 13px;
}

.team-dashboard .profile-edit-btn > div{
    width: 16px;
    height: 16px;
    margin: auto;
    z-index: 2;
    border-radius: 8px;
}

.team-dashboard .profile-image {
    width: 100%;
    position: relative;
}
