.ring p {
    font-size: 13px;
}

.ring {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.ring::before {
    content: '';
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    animation: ring 2s linear infinite;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
        box-shadow: 0px 5px 5px #21c0ff;
    }
    25% {
        transform: rotate(90deg);
        box-shadow: 0px 5px 5px #bc21ff;
    }
    50% {
        transform: rotate(180deg);
        box-shadow: 0px 5px 5px #ff2121;
    }
    75% {
        transform: rotate(270deg);
        box-shadow: 0px 5px 5px #fffb21;
    }
    100% {
        transform: rotate(360deg);
        box-shadow: 0px 5px 5px #21c0ff;
    }
}
