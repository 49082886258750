.login-page {
    height: 100vh;
    margin: -15px;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.3) 70%, #000),
    url('../../../assets/img/login/rocket_splash.jpeg') left no-repeat;
    background-size: 115%;
}

.login-container {
    width: 45%;
    max-width: 550px;
    margin: 50px 5%;
}

.login-container img {
    width: 270px;
    display: block;
    margin: 0 auto 50px;
}

.login-form {
    background-color: rgb(25 25 25 / 70%);
    width: 100%;
    border-radius: 10px;
    padding: 90px 65px 65px;
    backdrop-filter: blur(4px);
    text-align: center;
}

.login-form h1 {
    font-size: 34px;
    font-weight: 300;
    margin-bottom: 8px;
    color: var(--white);
}

.login-form h2 {
    font-size: 30px;
    color: #919191;
    font-weight: 200;
    margin-bottom: 45px;
}

.login-form input {
    border-radius: 8px;
    height: 50px;
    margin: 15px 0 10px;
    color: #1f1f1f;
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    outline: none;
}

.login-form input:focus {
    background-color: #dbdbdb;
    border-color: #dbdbdb;
    outline: none;
    box-shadow: none;
}

.login-form input.error {
    border-color: red;
}

.login-form p.error {
    color: red;
    font-size: 13px;
    line-height: 17px;
    font-weight: 200;
    text-align: left;
    letter-spacing: 0;
    margin: 0 8px;
}

.login-form .large-blue-button {
    height: 48px;
    margin-top: 15px;
}

@media (max-width: 1199px) {
    .login-form {
        padding: 60px 45px 45px;
    }
    .login-container img {
        width: 240px;
        margin: 0 auto 30px;
    }
    .login-form h1 {
        font-size: 30px;
        margin-bottom: 5px;
    }
    .login-form h2 {
        font-size: 26px;
        margin-bottom: 35px;
    }
}
