/******************* Details *******************/
.adventure-details {
    background: linear-gradient(180deg, rgba(50, 159, 255, 0.46) 0%, rgba(50, 159, 255, 0) 74.56%);
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
}

.adventure-details .overlap-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-image: url("../../../assets/img/adventure/adventure-blur.png");
    background-size: 100% 100%;
    padding: 20px;
    height: 100%;
    overflow: hidden;
}

.adventure-details .adventure-header h1 {
    margin-top: 8px;
    margin-bottom: 20px;
}

.adventure-details .adventure-data {
    width: 100%;
    height: 100%;
    overflow: hidden;
    gap: 20px;
}

.adventure-details .adventure-header .info-card,
.global-score .score-info .info-card {
    background-color: #1E1D1D;
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 13px;
    letter-spacing: 0;
}

.adventure-details .adventure-header .info-card .icon,
.global-score .score-info .info-card .icon {
    margin-right: 10px;
}

.adventure-details .data-section {
    background-color: #121212;
    border-radius: 10px;
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-size: 16px;
    font-weight: 300;
    margin-top: 15px;
}

.adventure-details .btn-container {
    margin-right: 15px;
    margin-top: 15px;
}

.adventure-details .large-dark-button {
    line-height: 18px;
}

.adventure-details .data-section h2 {
    font-size: 16px;
    font-weight: 300;
    margin: 15px 15px 15px 0;
}

.adventure-details .data-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: scroll;
}

.adventure-details .data-content::-webkit-scrollbar {
    display: none;
}

.adventure-details .box-100 {
    width: 100%;
    border-radius: 10px;
    background-color: #1E1D1D;
}

.adventure-details .profile {
    display: flex;
    padding: 20px;
    align-items: center;
}

.adventure-details .profile > div {
    padding-left: 20px;
}

.adventure-details .profile h3 {
    margin-bottom: 5px;
    font-size: 24px;
}

.adventure-details .profile h4 {
    margin-bottom: 10px;
    font-size: 16px;
    font-style: italic;
}

.adventure-details .profile span {
    font-style: italic;
    color: var(--silver-chalice);
}

.adventure-details .flex-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
}

.adventure-details .box-adventure-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #1E1D1D;
    width: 48%;
    border-radius: 10px;
    padding: 15px;
    height: 100px;
}

.adventure-details .box-adventure-info p {
    color: var(--silver-chalice);
    font-size: 12px;
}

.adventure-details .progress-bar-values {
    color: var(--silver-chalice);
    font-size: 12px;
}

.adventure-details .paragraph {
    color: var(--silver-chalice);
    font-size: 12px;
    font-style: italic;
}

.adventure-details .box-adventure-info strong {
    align-self: end;
    color: var(--white);
    font-size: 22px;
    font-weight: 600;
}

.adventure-details .box-adventure-info span {
    align-self: end;
    color: var(--silver-chalice);
    font-size: 10px;
}

.adventure-details .adventure-progress {
    height: 100px;
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.adventure-details .progress-title span {
    color: var(--silver-chalice);
    font-size: 12px;
}

.adventure-details .progressbar {
    display: flex;
    flex-direction: row;
}

.adventure-details .progress-bg {
    margin-top: 25px;
    position: relative;
    height: 5px;
    border-radius: 10px;
    background-color: var(--storm-dust);
    width: 85%;
}

.adventure-details .actual-progress {
    height: 5px;
    border-radius: 10px;
    background-color: #468dff;
    max-width: 100%;
}

.adventure-details .actual-progress-val {
    margin-top: 15px;
    width: 15%;
    text-align: right;
}

/******************* Ranking *******************/
.adventure-details .tab-header {
    padding: 10px;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0.01em;
    border-radius: 0;
    cursor: pointer;
}

.adventure-details .react-tabs {
    display: flex;
    flex-direction: column;
}

.adventure-details .react-tabs__tab-panel {
    height: 100%;
    overflow: scroll;
}

.adventure-details .react-tabs__tab-panel::-webkit-scrollbar {
    display: none;
}

.adventure-details .ranking-item,
.global-score .ranking-item {
    background-color: #1E1D1D;
    border-radius: 10px;
    padding: 15px;
    margin-top: 12px !important;
}

.adventure-details .ranking-item.selected-item,
.global-score .ranking-item.selected-item {
    background-color: rgba(255, 255, 255, 0.15);
}

.adventure-details .ranking-item .rank,
.global-score .ranking-item .rank {
    min-width: 30px;
    margin-right: 8px;
}

.adventure-details .ranking-item .score,
.global-score .ranking-item .score {
    font-size: 17px;
    font-weight: 600;
}

.adventure-details .ranking-item .ranking-info,
.global-score .ranking-item .ranking-info {
    flex: 1;
    margin-right: 15px;
}

.adventure-details .ranking-item .ranking-info p,
.global-score .ranking-item .ranking-info p {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}

.adventure-details .ranking-item .ranking-info span,
.global-score .ranking-item .ranking-info span {
    background-color: rgb(255 255 255 / 10%);
    padding: 3px 5px;
    color: var(--silver-chalice);
    border-radius: 4px;
    font-size: 9px;
    letter-spacing: 0.1em;
}

.adventure-details .you-btn {
    color: black;
    border-radius: 5px;
    background-color: white;
    font-size: 12px;
    font-weight: 400;
    padding: 4px 12px;
    cursor: pointer;
    letter-spacing: 0;
}
