/******************* Questions *******************/
.speed-quiz-question .dashboard-header .subtitle {
    font-size: 13px;
    border-radius: 5px;
    padding: 5px 8px;
    background-color: rgba(70, 141, 255, 0.2);
}

.speed-quiz-question .progress-bar {
    width: 100%;
    height: 6px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 10px;
}

.speed-quiz-question .progress {
    height: 6px;
    background-color: #468dff;
    border-radius: 10px;
    transition: width linear 1s;
}

.speed-quiz-result .result-item,
.speed-quiz-question .question-item {
    border-radius: 10px;
    background-color: #1E1D1D;
    padding: 15px;
    max-width: 600px;
}

.speed-quiz-question .question-item {
    margin: 15px auto 0;
}

.speed-quiz-question .item .large-blue-button {
    max-width: 600px;
}

.speed-quiz-question h2 {
    font-size: 16px;
    font-weight: 300;
    margin: 15px 0;
}

.speed-quiz-question .answer-item {
    width: calc(50% - 8px);
    font-size: 15px;
    font-weight: 300;
    color: #1E1D1D;
    padding: 15px;
    height: 230px;
    border-radius: 10px;
    background-color: #FFFFFF;
    border: 5px solid #1E1D1D;
}

.speed-quiz-question .answer-item:nth-child(1),
.speed-quiz-question .answer-item:nth-child(3) {
    margin-right: 10px;
}

.speed-quiz-question .answer-item:nth-child(1),
.speed-quiz-question .answer-item:nth-child(2) {
    margin-bottom: 10px;
}

.speed-quiz-question .answer-item.correct {
    color: #FFFFFF;
    background: linear-gradient(178deg, #2BE1B7 0%, #068570 100%);
}

.speed-quiz-question .answer-item.selected {
    border-color: #468dff;
}

/******************* Result *******************/
.speed-quiz-result .result-item {
    margin: 0 auto;
}
.speed-quiz-result .result-header {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 40px 25px;
    background-image: url("../../../assets/img/speedQuiz/bg-speedQuiz.jpeg");
}
.speed-quiz-result .result-header p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 15px;
}

.speed-quiz-result .result-header b {
    font-size: 30px;
    font-weight: 500;
}

.speed-quiz-result .result-score h2 {
    font-size: 26px;
    font-weight: 300;
    margin-top: 15px;
    text-transform: uppercase;
}
.speed-quiz-result .result-score h3 {
    font-size: 60px;
    font-weight: 300;
    margin-bottom: 0;
    margin-right: 20px;
}
.speed-quiz-result .result-score p {
    font-weight: 300;
    color: #9C9C9C;
    margin-bottom: 8px;
}
.speed-quiz-result .result-score span {
    color: #468dff;
    margin-right: 10px;
}
