/******************* Details *******************/
.mcq-question {
    background: linear-gradient(180deg, rgba(50, 159, 255, 0.46) 0%, rgba(50, 159, 255, 0) 74.56%);
    border-radius: 10px;
    height: 100%;
}

.mcq-question .overlap-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../../assets/img/adventure/adventure-blur.png");
    background-size: 100% 100%;
    padding: 20px;
    height: 100%;
}

.mcq-question .start-mqc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;

}

.mcq-question .description {
    margin-bottom: 20px;
    background-color: var(--heavy-metal);
    border-radius: 10px;
}

.mcq-question .question-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    background-color: #1E1D1D;
    padding: 20px;
    width: 100%;
    max-width: 680px;
    margin: 0 auto;
}

.mcq-question .question-item img {
    border-radius: 8px;
}

.mcq-question .question-item > span {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    margin: 5px 0 15px;
}

.mcq-question .question-item > p {
    font-size: 90%;
    margin-bottom: 15px;
}

.mcq-question .answer-item {
    width: calc(50% - 8px);
    aspect-ratio: 1;
    font-size: 100%;
    font-weight: 300;
    color: #1E1D1D;
    padding: 15px;
    border-radius: 10px;
    background-color: #FFFFFF;
    border: 5px solid #1E1D1D;
}

.mcq-question .answer-item.correct {
    color: #FFFFFF;
    background: linear-gradient(178deg, #2BE1B7 0%, #068570 100%);
}

.mcq-question .answer-item.selected {
    border-color: #468dff;
}

.mcq-question .text-shadow {
    text-shadow: 0 0 20px var(--licorice);
}
